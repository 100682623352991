import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Tile, { TileSelectionModes } from "../../components/tile/Tile";
import { SessionContext, isOniqDev, isOniqEmployee } from "../../contexts/SessionContext";
import i18n from "../../i18n";
import { isRcaVisible } from "../rca/RootCauseView";
import { RcaType } from "../../contexts/ContextTypes";
import { QuantityType, getAssignedGroupingKeys, getAssignedQuantities } from "../../utils/Quantities";

type categoryGroup = {
    category?: string;
    analysesList?: AnalysesGroup[];
}

type AnalysesGroup = {
    title?: string;
    isSelected?: boolean;
    isVisible?: boolean;
    description: string;
    path?: string;
    icon?:string;
};

export default function AnalysesView() {

    const { projectId } = useParams<{
        projectId: string,
    }>();

    const session = useContext(SessionContext);
    session.setProject(projectId);

    const navigate = useNavigate();

    const isOniqUser = isOniqEmployee(session);

    const isOniqDevUser = isOniqDev(session);

    const hasYieldData = getAssignedQuantities(session.project?.eventKeys, QuantityType.Yield, false).length > 0;

    const assignedGroupingKeys = getAssignedGroupingKeys(session.project?.eventKeys);

    const analyses: categoryGroup[] = [];

    // Setup matrix endpoint needs pass id and product to generate results.
    // If the visibility constraints change, be sure to also adept this in
    // the RCA results page, where the visibility of the "Show setup matrix"
    // button is checked
    const isSetupMatrix = session.project?.eventKeys?.product !== undefined &&
        session.project.eventKeys.isProduction !== undefined &&
        session.project.eventKeys.passId !== undefined;


    // Show master data download functionality
    const hasRoutingId = session.project?.uploads?.cases?.columnMapping?.routingId;
    const isAllowedMasterDataDownload = hasRoutingId && (isOniqDevUser || !!session.project?.features?.allowMasterDataDownload);

    // Show root cause analyses
    // Show root cause analyses only if there is at least one that the user may see
    const rcas = [{
        isVisible: isRcaVisible(RcaType.Time, session, isOniqUser),
        path: "/analyses/rca/deviation"
    }, {
        isVisible: isRcaVisible(RcaType.ThroughputTime, session, isOniqUser),
        path: "/analyses/rca/throughput-time"
    }, {
        isVisible: isRcaVisible(RcaType.Throughput, session, isOniqUser),
        path: "/analyses/rca/throughput"
    }, {
        isVisible: isRcaVisible(RcaType.Quality, session, isOniqUser),
        path: "/analyses/rca/quality"
    }].filter(r => r.isVisible);

    const valueStreamAnalysesList = [
        {
            title: "common.bottleneck",
            description: "common.analysesView.descriptions.bottleneck",
            isVisible: isRcaVisible(RcaType.Bottleneck, session, isOniqUser),
            path: "/analyses/bottleneck/bottleneck",
            icon: "#radix-lightning-bolt",
        },
        {
            title: "common.valueStreamDiagram",
            description: "common.analysesView.descriptions.valueStream",
            // We need yield data as well as grouping keys other than "none"
            isVisible: hasYieldData && assignedGroupingKeys.length > 1 && session.project?.features?.allowTaktTime !== false,
            path: "/analyses/value-stream/summary",
            icon: "#radix-eye-open",
        },
        {
            title: "workflows.taktTime.title",
            description: "common.analysesView.descriptions.cycleTimes",
            // We need yield data as well as grouping keys other than "none"
            isVisible: hasYieldData && assignedGroupingKeys.length > 1 && session.project?.features?.allowTaktTime !== false,
            path: "/analyses/cycle-time/summary",
            icon: "#radix-lap-timer",
        }
    ];

    analyses.push({
        category: "common.analysesView.valueStream",
        analysesList: valueStreamAnalysesList.filter(s => s.isVisible)
    });

    analyses.push({
        category: "common.analysesView.workplace",
        analysesList: [
            {
                title: "common.analysesView.processWorkplace",
                isVisible: session.project?.features?.allowProcessWorkplaceComparison !== false,
                description: "common.analysesView.descriptions.workplace",
                path: "/analyses/workplaces/comparison",
                icon: "#radix-layout",
            },
            {
                title: "common.analysesView.equipmentWorkplace",
                isVisible: !!session.project?.features?.allowEquipmentComparison || isOniqEmployee(session),
                description: "common.analysesView.descriptions.equipment",
                path: "/analyses/equipments/comparison",
                icon: "#radix-timer",
            },
            ...isSetupMatrix ? [{
                title: "common.changeover",
                description: "common.analysesView.descriptions.changeover",
                isVisible: session.project?.features?.allowSetupMatrix || isOniqUser,
                path: "/analyses/setup/matrix",
                icon: "#radix-view-grid",
            }] : []
        ].filter(s => s.isVisible)
    });

    if (isAllowedMasterDataDownload) {
        analyses.push({
            category: "common.analysesView.masterData",
            analysesList: [
                {
                    title: "common.analysesView.masterDataDownload",
                    description: "common.analysesView.descriptions.masterDataDownload",
                    path: "/analyses/master-data-download/master-data-download",
                    icon: "#download",
                }
            ]
        });

    }

    // Organizational losses require interruptions and reasons to be labelled
    if (isRcaVisible(RcaType.OrgLosses, session, isOniqUser))
        analyses.push({
            category: "common.analysesView.organisation",
            analysesList: [
                {
                    title: "common.losses",
                    description: "common.analysesView.descriptions.losses",
                    path: "/analyses/losses/organizational",
                    icon: "#radix-double-arrow-down",
                }
            ]
        });

    if (rcas.length > 0)
        analyses.push({
            category: "common.analysesView.rootCauseAnalysis",
            analysesList: [
                {
                    title: "navigation.rootCauses",
                    description: "common.analysesView.descriptions.rca",
                    path: rcas[0].path,
                    icon: "#radix-magnifying-glass",
                }
            ]
        });

    

    return <div className="analysisView">
        <div className="greetingContainer">
            <div className="greeting">{i18n.t("common.analyses")}</div>
        </div>

        <div className="analysisContainer">
            {(analyses ?? []).map((categoryItem, index) => (
                <div key={index}>
                    {categoryItem.analysesList !== undefined && categoryItem.analysesList?.length > 0 &&
                        <div className="categoryContainer" >
                            <div className="categoryTitle">{i18n.t(`${categoryItem.category}`)}</div>
                            <div className="separator"></div>
                            <div className="tilesContainer" data-testid="container">
                                {(categoryItem.analysesList ?? [])?.map((analysis, subIndex) => (
                                    <Tile
                                        key={`${index}-${subIndex}`}
                                        selectionMode={TileSelectionModes.Pinnable}
                                        isAnalyses={true}
                                        title={i18n.t(`${analysis.title}`) ?? ""}
                                        description={i18n.t(`${analysis.description}`) ?? ""}
                                        onClick={() => {
                                            navigate("/projects/" + session.projectId + analysis.path);
                                        }}
                                        analysesIcon={analysis.icon}
                                    />
                                ))}
                            </div>
                        </div>}
                </div>
            ))}
        </div>
    </div>;

}
