import React, { useContext } from "react";
import Global from "../../Global";
import DfgControls, { getValidDfgControlSettings } from "../../components/controls/DfgControls";
import { VisibilityOptions } from "../../components/controls/GroupingKeyControls";
import SideStatisticsValueStream from "../../components/side-statistics/SideStatisticsValueStream";
import { TabbedView, TabbedViewType } from "../../components/tabbed-view/TabbedView";
import { AggregationTypes } from "../../contexts/ContextTypes";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext, SortByType } from "../../contexts/SettingsContext";
import { GroupingKeys } from "../../models/Dfg";
import { KpiPresets, KpiTypes } from "../../models/KpiTypes";
import { viewSettingsInitialization } from "../../utils/Initializers";
import { ObjectMerger } from "../../utils/ObjectMerger";
import { QuantityType, getAssignedQuantities } from "../../utils/Quantities";
import { ValueStreamGraph } from "../value-stream/ValueStreamGraph";
import { ViewSubtitle } from "../../components/tabbed-view/ViewSubtitle";


export function KaizenValueStreamView() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const quantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.Yield, false);
    const baseQuantities = quantities.map(q => q.baseQuantity);

    // Exclude the OEE kpi from the list if we are not grouping by machine
    const kpiList = KpiPresets.valueStreamTaktTimeKpis.filter(kpi => settings.groupingKey === GroupingKeys.Machine || settings.groupingKey === GroupingKeys.MachineValueStream || kpi !== KpiTypes.OverallEquipmentEffectiveness);

    // We explicitely want to set the grouping to PassValueStream or MachineValueStream as defaults here.
    // Except we are running in storybook where we also want to test other groupings.
    const allowedDefaultGroupingKeys = (Global.isRunningStorybook || Global.isRunningJestTest) ?
        [GroupingKeys.PassValueStream, GroupingKeys.MachineValueStream, GroupingKeys.MachineObjectTypeValueStream, GroupingKeys.MachineObjectType, GroupingKeys.ObjectTypeValueStream, GroupingKeys.ObjectType] :
        [GroupingKeys.PassValueStream, GroupingKeys.MachineValueStream];

    // This is the only view which should not affect the grouping key.
    // This is a hacky workaround for that: We'll store the grouping that
    // we had when initializing this view, and restore it afterwards. To do so
    // we also have to jump through some hoops to make sure that the cleanup
    // is only called when the view is actually unloaded.
    const viewLoaded = React.useRef(new Date().getTime());
    React.useEffect(() => {
        return () => {
            if (new Date().getTime() - viewLoaded.current > 500) {
                queueMicrotask(() => settings.mergeSet({ groupingKey: settings.groupingKey }));
            }
        };
    }, []);

    const views: TabbedViewType[] = [{
        tabTitle: "explorer.dfg",
        tabSlug: "summary",
        selectionTrigger: (settings) => {
            return settings.selection.node !== undefined || settings.selection.edge !== undefined;
        },
        controller: <DfgControls kpis={kpiList} hasIndentation={false} groupingKeyOptions={{ none: VisibilityOptions.Hidden, location: VisibilityOptions.Hidden, machineType: VisibilityOptions.Hidden }}  disableDetailedControls={true}/>,
        stats: <SideStatisticsValueStream kpiList={kpiList}/>,
        activator: (preferences) => {
            let temp = viewSettingsInitialization(session, settings, preferences, undefined, {
                quantities: baseQuantities,
                sortBy: [SortByType.Kpi],
                kpis: KpiPresets.valueStreamTaktTimeKpis,
                groupingKeys: allowedDefaultGroupingKeys,
                aggregationTypes: [AggregationTypes.Product],
            });

            if (!temp)
                return;

            temp = ObjectMerger.mergeObject(temp, getValidDfgControlSettings(session, temp, {
                kpis: KpiPresets.valueStreamTaktTimeKpis,
                groupingKeyOptions: {
                    none: VisibilityOptions.Hidden,
                    location: VisibilityOptions.Hidden,
                    machineType: VisibilityOptions.Hidden
                }
            }));

            settings.set(temp);
        },
        spotlightId: "KaizenValueStream",
        content: <ValueStreamGraph kpis={kpiList}/>,
    }];

    return <TabbedView
        subtitle={<ViewSubtitle />}
        breadcrumbs={[{
            label: "common.analyses",
            url: `/projects/${session.projectId}/analyses`,
        }, {
            label: "common.valueStreamDiagram"
        }]}
        pages={views} />;
}
